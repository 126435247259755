.blog-left-page {
    width: 65%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
}



.show-more {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    width: 200px;
    color: #16A7F0;
    font-size: 22px;
    cursor: pointer;
    margin: 20px auto 0 auto;
    position: absolute;
    bottom: -70px;
    left: 0;
    right: 0;
    
    &:hover {
        .sow-more-icon {

            transform: rotate(90deg) translatex(5px);
        }
    }

}

.sow-more-icon {
    transform: rotate(90deg);
    margin-left: 5px;
    transition: all ease 0.3s;
}

@media only screen and (max-width: 960px) {


    .blog-left-page {
        width: 100%;
    }

}


@media only screen and (max-width: 480px) {


    .blog-left-page {
        width: 100%;
    }

}