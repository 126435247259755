.slider-name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    text-align: start;
}

.slider-text {
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    opacity: 0.7;
    margin-top: 10px;
    text-align: start;
}

.person-name {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
}

.person-work {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 142.5%;
    display: block;
    color: #F0F0F0;
    margin-top: 21px;
}

.person-information {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 171.5%;
    margin-top: 43px;
    color: #FFFFFF;
}

.slider-images {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}

.slider-images-block {
    height: 394px;
}

.slider-info {
    background: radial-gradient(50% 50% at 0% 50%, #3D3FA3 0%, #2B2D66 100%);
    box-sizing: border-box;
    box-shadow: 0px 24px 48px rgba(0, 1, 44, 0.157184);
    border-radius: 12px;
    padding: 22px;
    transform: translateY(-10px);
    z-index: 2;
    width: 100%;
    transition: all ease 0.5s;

}


.slick-current {
    .slider-info {
       background: #3C6FF2;
    }
}

.person-description {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 171.5%;
    color: #FFFFFF;
    opacity: 0.7;
    text-align: left;
    margin-top: 17px;
    display: none;
}

.slider-images-block {
    z-index: 3;
    position: relative;
}


@media only screen and (max-width: 480px) {

    .person-description {
        display: block;
        min-height: 160px;
    }

    .slider-text {
        font-size: 18px;
        line-height: 142.5%;
    }

    .slider-name {
        font-size: 24px;
        line-height: 29px;
    }
    .slider-images-block {
        height: 400px;
        img {
            width: 100%;
            height: 100%;
        }
    }

    .slick-current {
        .slider-info {
            background: radial-gradient(50% 50% at 0% 50%, #3D3FA3 0%, #2B2D66 100%);
        }
    }


}