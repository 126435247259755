.blog-right-block {
    background: #25265B;
    border: 1px solid rgba(255, 255, 255, 0.12);
    box-sizing: border-box;
    border-radius: 15px;
    padding: 30px;
    margin-top: 50px;
    margin-left: 104px;
    height: 100%;
}

.categoris {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;


}

.blog4-right-block-link {
    margin-bottom: 54px;
    margin-top: 23px;
    
    .blog-right-links {
        display: block;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        text-decoration: none;
        color: #FFFFFF;
        padding: 10px 0 10px 10px;
        transition: all ease 0.5s;
        border: none;
        background: none;
        cursor: pointer;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: -30px;
            margin: auto;
            width: 0 ;
            height: 0;
            background: #fff;
            transition: all ease 0.2s;
        }
        &:hover::before {
           height: 100%;
           width: 2px;
        }

        &.activ {
            color: #16A7F0;
        }
    }
}
@media only screen and (max-width: 880px) {


    .blog-right-block {
        margin-left: auto;
        overflow: hidden;
        padding: 0;
        border: none;
        width: 0;
        height: 0;

        &.active {
            width: 100%;
            height: 100%;
            border: 1px solid rgba(255, 255, 255, 0.12);
            padding: 30px;
        }
    }

}


@media only screen and (max-width: 480px) {


   .blog-right-block {
       margin-left: auto;
        overflow: hidden;
        padding: 0;
        border: none;
        width: 0;
        height: 0;
        &.active {
            width: 100%;
            height: 100%;
            border: 1px solid rgba(255, 255, 255, 0.12);
            padding: 30px;
        }
   }

}
