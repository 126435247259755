.blog-card {
    width: 100%;
    overflow: hidden;
    margin-top: 50px;
    text-decoration: none;
    transition: all ease 0.5s;
    overflow: hidden;
    margin-top: 50px;
    display: block;

    &:hover {
        .card-img {
            transform: scale(1.1);
        }
    }
}
.blog-card_stil {
    width: 45%;
}

.card-bottom-div {
    display: flex;
    align-items: center;
}
.blog-card-icon {
    margin-left: 10px;
}

.blog-card-info {
    background: radial-gradient(50% 50% at 0% 50%, #3D3FA3 0%, #2B2D66 100%);
    border: 2px solid rgba(255, 255, 255, 0.0782099);
    box-sizing: border-box;
    box-shadow: 0px 24px 48px rgba(0, 1, 44, 0.157184);
    border-radius: 0 0 12px 12px;
    padding: 10px;
    width: 100%;
}

.blog-card-img-block {
    width: 100%;
    height: 300px;
    overflow: hidden;
    border-radius: 12px 12px 0 0;
}

.card-img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: all ease 0.5s;
    object-fit: cover;
}

.card-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    height: 70px;
    overflow: hidden;
    color: #FFFFFF;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
    ;

    padding: 20px 0;
}

.card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.card-span {
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 23px;
    opacity: 0.7;
    color: #FFFFFF;
}

.card-link {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #00A5EF;
}

.card-img {
    background: #fff;
}

 .portfolio_card {
     width: 32%;
     margin: 0 7px;
 }


@media only screen and (max-width: 1024px) {


     .blog-card {
         width: 100%;
         overflow: hidden;
         margin-top: 50px;
         text-decoration: none;
     }


    .blog-card-info {
        background: radial-gradient(50% 50% at 0% 50%, #3D3FA3 0%, #2B2D66 100%);
        border: 2px solid rgba(255, 255, 255, 0.0782099);
        box-sizing: border-box;
        box-shadow: 0px 24px 48px rgba(0, 1, 44, 0.157184);
        border-radius: 0 0 12px 12px;
        padding: 10px;
        width: 100%;
        width: 270px;
    }

    .card-img {
        width: 270px;
        top: 17px;
        position: relative;
        height: 100%;
    }

    .blog-card_stil {
        width: 50%;
    }

     .portfolio_card {
         width: 32%;
     }

}

@media only screen and (max-width: 960px) {


    .blog-card {
        width: 100%;
        overflow: hidden;
        margin-top: 50px;
        text-decoration: none;
    }

    .card-img {
        width: 100%;
        object-fit: cover;
        height: 100%;
    }

    .blog-card-info {
        width: 100%;
    }

    .blog-card-img-block {
        width: 100%;
    }

    .portfolio_card {
        width: 45%;
    }


    .blog-card_stil {
        width: 45%;
    }

}



@media only screen and (max-width: 480px) {

     .blog-card_stil {
         width: 100%;
     }


     .blog-card {
         width: 100%;
         overflow: hidden;
         margin-top: 50px;
         text-decoration: none;
     }

     .card-img {
         width: 100%;
         height: 100%;
         object-position: top;
     }

     .blog-card-info {
         width: 100%;
     }

     .blog-card-img-block {
         width: 100%;
     }

      .portfolio_card {
          width: 100%;
      }
}

