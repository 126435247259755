body {
  margin: 0;
  padding: 0;
  font-family: "Fira Sans";
  background: linear-gradient(
    to bottom left,
    #36377c,
    #181942,
    #181942,
    #181942,
    #36377c
  );
  background-repeat: no-repeat;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}


#root {
  overflow: hidden;
}
.container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
}
.filter-btn{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  button{
    cursor: pointer;
    background-color: unset;
    color:#dadce0 ;
    border: 1px solid #dadce0;
    border-radius: 20px;
    padding: 10px 20px;
    &.selected{
  background-color: #00A5EF;
    }
  }:hover{
    background-color: #00A5EF;
  }
}
.selected{
  cursor: pointer;
  color:#dadce0 ;
  border: 1px solid #dadce0;
  border-radius: 20px;
  padding: 10px 20px;
}
.container1 {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
}
.container2 {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
}

.spinner {
  position: relative;
  margin: auto;
  box-sizing: border-box;
  background-clip: padding-box;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  border: 4px solid rgba(255, 255, 255, 0.1);
  -webkit-mask: linear-gradient(rgba(0, 0, 0, 0.1), #000000 90%);
  transform-origin: 50% 60%;
  transform: perspective(200px) rotateX(66deg);
  animation: spinner-wiggle 1.2s infinite;
}
.spinner:before,
.spinner:after {
  content: "";
  position: absolute;
  margin: -4px;
  box-sizing: inherit;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  opacity: 0.05;
  border: inherit;
  border-color: transparent;
  animation: spinner-spin 1.2s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
    spinner-fade 1.2s linear infinite;
}

.spinner:before {
  border-top-color: #66e6ff;
}

.spinner:after {
  border-top-color: #f0db75;
  animation-delay: 0.3s;
}

@keyframes spinner-spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinner-fade {
  20% {
    opacity: 0.1;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 0.1;
  }
}

.show-more-icon {
  width: 50px;
  height: 50px;
}

.slick-dots li button:before {
  display: none !important;
}

@media only screen and (max-width: 1048px) {

  body {
    margin: 0;
    padding: 0;
    font-family: "Fira Sans";
    background-image: linear-gradient(
      to bottom left,
      #36377c,
      #181942,
      #181942,
      #181942,
      #36377c
    );
    // background: red;
    background-repeat: no-repeat;
    overflow-x: hidden;
  }

  .iframe {
    width: 450px;
    height: 326px;
  }
}
.image-gallery-icon {
  width: 50px !important;
  box-sizing: border-box;
  height: 50px !important;
  display: block !important;
  border: none !important;
  border-radius: 50%;
  z-index: 999 !important;
  padding: 0 !important;
  background-repeat: no-repeat !important;
  svg {
    display: none;
  }
  &.image-gallery-left-nav {
    background: url(../assets/left-switch-slider.svg),
      radial-gradient(50% 50% at 0% 50%, #3d3fa3 0%, #2b2d66 100%) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    left: -25px !important;
  }

  &.image-gallery-right-nav {
    background: url(../assets/right-switch-slider.svg),
      radial-gradient(50% 50% at 0% 50%, #3d3fa3 0%, #2b2d66 100%) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    right: -25px !important;
  }
}

