.sponsor {
  // background: linear-gradient(270deg, #1e1f4d 0%, #3a3cae 100%),
  //   linear-gradient(180deg, #4d4f95 0%, #2b2d66 100%);
  // border: 2px solid rgba(255, 255, 255, 0.0782099);
  // box-sizing: border-box;
  // box-shadow: 0px 24px 48px rgba(0, 1, 44, 0.157184);
  border-radius: 12px;
  padding: 59px 38px;
  position: relative;
  margin-top: 45px;
  ul {
    display: flex !important;
    top: 200px;
    justify-content: center;
    li {
      display: none;
      margin: 0 10px;
      cursor: pointer;
      background: #4d5096;
      height: 5px;
      width: 22px;
      &.slick-active {
        background: #fff !important;
        height: 5px;
        width: 22px;
        margin: 0 10px;
      }
    }
  }

  .slick-arrow {
    display: flex !important;
  }
}

.home5 {
  margin: auto;
  display: block;
}

.home-page5 {
  margin-top: 172px;
}

.sponsor-logotip {
  width: 200px !important;
  margin: auto;
}

@media only screen and (max-width: 960px) {

  .home-page5 {
    margin-top: 60px;
  }

  .sponsor {
    padding: 15px;
  }

  .sponsor-logotip {
    width: 70px !important;
    object-fit: contain;
  }


  .sponsor ul {
    top: 130px;
  }

  .sponsor-logotip {
    margin: auto;
  }
}



@media only screen and (max-width: 480px) {

  .home-page5 {
    margin-top: 60px;
  }

  .sponsor {
    padding: 15px;
  }

  .sponsor-logotip {
    width: 70px !important;
    object-fit: contain;
  }


  .sponsor ul {
    top: 130px;
  }

  .sponsor-logotip {
    margin: auto;
  }
}


