.contact-pages1 {
    display: flex;
    justify-content: space-between;
    margin-top: 96px;
    
}

.contact-pages1-left {
    background: #25265B;
    border: 2px solid rgba(255, 255, 255, 0.12);
    box-sizing: border-box;
    border-radius: 20px;
    padding: 45px;
    position: relative;
    left: 0;
    height: 472px;
    width: 100%;
}

.contact-pages1-left_block {
    width: 45%;
}

.iframe-maps {
    position: relative;
    right: 0;
    width: 100%;
    height: 472px;
    border-radius: 20px;
    position: absolute;

}

.contact-information {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);;
    padding-bottom: 19px;
}

.contact-link {
    display: block;
    text-decoration: none;
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 23px;
    opacity: 0.7;
    color: #FFFFFF;
    margin: 0 0 39px 0;
    padding-left: 40px;
    position: relative;
    transition: all ease 0.5s;
    max-width: 217px;
    &:hover {
        opacity: 1;
    }
    &:nth-child(1) {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
           
            margin: auto;
            width: 22px;
            height: 22px;
            background-image: url('../../assets/images/img/footer/Iconly/Light/Stroke.svg');
            background-repeat: no-repeat;
        }
    }

    &:nth-child(2) {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
           
            margin: auto;
            width: 22px;
            height: 22px;
            background-image: url('../../assets/images/img/footer/Iconly/Light/Message.svg');
            background-repeat: no-repeat;
        }
    }

    &:nth-child(3) {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
           
            margin: auto;
            width: 22px;
            height: 22px;
            background-image: url('../../assets/images/img/footer/Iconly/Light/Location.svg');
            background-repeat: no-repeat;
        }
        &:hover {
            opacity: 0.7;
        }
    }
}

.contact-link-block {
    margin-top: 31px;
}

.social-block {
    margin-top: 21px;
    display: flex;
    align-items: center;
}

.social-icon {
    width: 22px;
    height: 22px;
}

.social-link {
    margin-right: 40px;
}



@media only screen and (max-width: 1024px) {




    .contact-pages1-left {
        background: #25265B;
        border: 2px solid rgba(255, 255, 255, 0.12);
        box-sizing: border-box;
        border-radius: 20px;
        padding: 45px 140px;
        position: relative;
        left: 0;
        height: 472px;
    }
    

    .iframe-maps {
        position: relative;
        right: 0;
        width: 450px;
        height: 472px;
        border-radius: 20px;
        position: absolute;
    
    }

}
@media only screen and (max-width: 960px) {


    .contact-pages1 {
        flex-direction: column;
    }



    .contact-pages1-left {
        width: 100%;
        padding: 20px;
    }

    .iframe-maps {
        width: 100%;
        margin-top: 30px;
    }
     .contact-pages1-left_block {
         width: 100%;
     }


}

@media only screen and (max-width: 480px) {

   .contact-pages1-left_block {
       width: 100%;
   }
    .contact-pages1 {
        flex-direction: column;
    }

   

     .contact-pages1-left {
         width: 100%;
         padding: 20px;
     }

     .iframe-maps {
         width: 100%;
         margin-top: 30px;
     }

    
}
