.about-page3-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.about-page3 {
    background: #25265B;
    padding: 59px 0;
    margin-top: 150px;
}
.about-page3-card{
    &:nth-child(3){
        .about-page3-card-text{
            &::after{
                content: "+";
            }
        }
    }
    &:nth-child(4) {
        .about-page3-card-text {
            &::after {
                content: "+";
            }
        }
    }
}

.about-page3-card-text {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:last-child {
        display: none;
    }
}

.about-page3-card-info {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    margin-top: 11px;
    color: #FFFFFF;
    text-align: center;
    text-transform: uppercase;
}

@media only screen and (max-width: 960px) {


    .about-page3-card-text {
        font-size: 30px;
        line-height: 33px;
    }

    .about-page3-card-info {
        font-size: 14px;
        line-height: 17px;
        font-weight: normal;
    }

    .about-page3 {
        margin-top: 30px;
        padding: 30px 0;
    }
}


@media only screen and (max-width: 480px) {


    .about-page3-card-text {
        font-size: 30px;
        line-height: 33px;
    }

    .about-page3-card-info {
        font-size: 14px;
        line-height: 17px;
        font-weight: normal;
    }

    .about-page3 {
        margin-top: 30px;
        padding: 30px 0;
    }
}