.homepage-card {
    text-decoration: none;
    min-height:380px;
    box-sizing: border-box;
    border-radius: 12px;
    width: auto;
    max-width: 1080px;
    margin: 0 20px;
    display: block;
    text-decoration: none;
    &:hover {
        .homepage-card-img {
            transform: scale(1.1);
        }
    }
}

.homepage-card-inner {
    padding: 30px;
    background: radial-gradient(50% 50% at 0% 50%, #3D3FA3 0%, #2B2D66 100%);
    position: relative;
    box-shadow: 0px 24px 48px rgba(0, 1, 44, 0.157184);
    top: -10px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    min-height: 200px;
}

.homepage-card-img {
    width: 100%;
    transition: all ease 0.5s;
    object-fit: cover;
    height: 100%;
}

.homepage-card-img-block {
    overflow: hidden;
    border-radius: 12px 12px 0 0;
    height: 354px;
}

.homepage-card-title {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 19px;
    color: #FFFFFF;
    max-width: 100%;
    height: 57px;
    overflow: hidden;
}

.homepage-card-info {
    height: 158px;
    overflow: hidden;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    opacity: 0.7;
    line-height: 170.5%;
    color: #F0F0F0;
    margin-top: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}

@media only screen and (max-width: 1024px) {

     .homepage-card {
         margin: 0 10px;
         max-width: 450px;
     }

}


@media only screen and (max-width: 480px) {

    .homepage-card {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        margin:  auto;
    }

    .homepage-card-img {
        object-position: top;
    }
    .homepage-card-info {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 170.5%;
    }
}
