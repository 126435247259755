.home-page2-left {
    width: 44%;
}

.home-page2-text1 {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 171.5%;
    opacity: 0.7;
    margin-top: 15px;
    color: #FFFFFF;
}

.text1 {
    margin-top: 40px;
}

.home-page2-link {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    position: relative;
    color: #00A5EF;
    margin-top: 45px;
    display: inline-block;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -30px;
        bottom: 0;
        margin: auto;
        background-repeat: no-repeat;

    }
}

.home-page2-right {
    display: flex;
    width: 541px;
    flex-wrap: wrap;
    background: #25265B;
    border-radius: 45px 0px 0px 45px;
    padding: 35px;
    position: absolute;
    top: 0;
    right: 0;
}

.home-page2-right-box {
    padding: 35px;
    width: 190px;

    &:nth-child(1) {
        
        border-right: 1px solid #202151;
    }
    &:nth-child(3) {
        border-top: 1px solid #202151;
    }

    &:nth-child(4) {
        border-left: 1px solid #202151;
        border-top: 1px solid #202151;
    }

}

.box-span {
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 23px;
    opacity: 0.7;
    color: #FFFFFF;
    text-align: center;
    display: block;
}

.box-name {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    color: #FFFFFF;
}

.box-text {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase;
}

.home-page2-block {
    position: relative;
}


@media only screen and (max-width: 1024px) {


    .home-page2-right-box {
        padding: 35px;
        width: 104px;
    }


    .home-page2-right {
        display: flex;
        width: 350px;
        flex-wrap: wrap;
        background: #25265B;
        border-radius: 45px 0px 0px 45px;
        padding: 35px;
        position: absolute;
        top: 0;
        right: 0;
    }

    .youtube-iframe {
        width: 488px;
        height: 247px;
        display: flex;
        margin: 100px auto 0 auto;
        z-index: 11;
        position: relative;
        left: 0;
        border-radius: 40px;
        top: -79px;
    }


}

@media only screen and (max-width: 840px) {

    .home-page2-left {
        width: 100%;
    }

    .home-page2-right {
        position: initial;
        padding: 15px;
        border-radius: 45px;
        width: 100%;
        box-sizing: border-box;
        margin-top: 46px;
    }

    .home-page2-right-box {
        padding: 40px;
        box-sizing: border-box;
        width: 50%;
    }

    .home-page2-block {
        margin-top: 120px;
    }

}




@media only screen and (max-width: 480px) {

    .home-page2-right {
        position: initial;
        padding: 15px;
        border-radius: 45px;
        width: 100%;
        box-sizing: border-box;
        margin-top: 46px;
    }
    

    .home-page2 {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }


    .home-page2-left {
        width: 100%;
    }

    .shadov-text .shadov {
        font-weight: bold;
        font-size: 80px;
        top: -59px;
        // left: -8px;
        line-height: 105px;
    }

    .shadov-text .text {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
    }

    .home-page2-text1 {
        font-weight: 300;
        font-size: 16px;
        line-height: 171.5%;
    }

    .home-page2-link {
        text-align: right;
        display: block;
        margin-right: 30px;
    }

     .box-name {
         font-weight: 600;
         font-size: 36px;
         line-height: 43px;
     }

     .box-text {
         font-size: 18px;
         line-height: 22px;
     }

     .home-page2-right-box {
         padding: 40px;
         box-sizing: border-box;
         width: 50%;
     }


     .home-page2-block {
         margin-top: 55px;
     }
    
}
