.about-page5 {
    margin-top: 165px;
}

@media only screen and (max-width: 960px) {


    .about-page5 {
        margin-top: 65px;
    }
}

@media only screen and (max-width: 480px) {


    .about-page5 {
        margin-top: 65px;
    }
}