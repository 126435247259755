.home-page8 {
  margin-top: 250px;
  ul {
    display: none !important;
  }
}

.homepage-card-block {
  margin-top: 82px;
}

.homepage8-link-block {
  display: flex;
  justify-content: center;
  margin-top: 94px;
}

.homepage8-link {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  text-decoration: none;
  padding: 10px 20px;
  border: 1px solid #fff;
  border-radius: 12px;
  transition: all ease 0.5s;
  color: #FFFFFF;
  &:hover {
    border: 1px solid #00A5EF;
    background: transparent;
    color: #00A5EF;
  }
}

@media only screen and (max-width: 480px) {

  
.home-page8 {
  margin-top: 0;
}

.home-page8 ul {
  display: block !important; 
}


.homepage8-link-block {
  margin-top: 40px;
}


}

