.accordion-block-inner {
    display: flex;
    justify-content: space-between;
}

.accordion-block-left {
    width: 45%;
}

.accordion-block-right {
    width: 45%;
    margin-top: 50px;
}

.accordion-block-right-img {
    width: 461px;
}

.accordion-block-right-text {
    margin-top: 42px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    max-width: 461px;
}

.accordion-block-right-info {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 171.5%;
    opacity: 0.7;
    color: #FFFFFF;
    max-width: 461px;
    margin-top: 30px;
}

.accordion-text {
    font-style: normal;
font-weight: 350;
font-size: 48px;
line-height: 58px;
color: #FFFFFF;
}

.accordion-block {
    margin-top: 150px;
}

@media only screen and (max-width: 1024px) {


    .accordion-block-right-img {
        width: 400px;
    }

    .accordion-btn {
        border: none;
        background: none;
        background: radial-gradient(239.52% 1131.28% at 0% 50%, #3D3FA3 0%, #2B2D66 100%);
        border: 2px solid rgba(255, 255, 255, 0.0782099);
        box-sizing: border-box;
        box-shadow: 0px 24px 48px rgb(0 1 44 / 16%);
        border-radius: 12px;
        padding: 16px;
        width: 100%;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: all ease 0.5s;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
        color: #FFFFFF;
    }
}

@media only screen and (max-width: 880px) {

    .accordion-block-right {
        width: 100%;
    }

    .accordion-block-right-img {
        width: 100%;
    }


    .accordion-text {
        font-weight: 600;
        font-size: 38px;
        line-height: 46px;
    }


    .accordion-block-inner {
        flex-direction: column;
    }

    .accordion-block-left {
        width: 100%;
    }

    .accordion-btn {
        font-weight: 500;
        font-size: 10px;
        line-height: 135.5%;
    }

    .accordion-info {
        font-weight: 300;
        font-size: 16px;
        line-height: 171.5%;
    }


}

@media only screen and (max-width: 480px) {

    .accordion-block-right {
        width: 100%;
    }

    .accordion-block-right-img {
        width: 100%;
    }

    
    .accordion-text {
        font-weight: 600;
        font-size: 38px;
        line-height: 46px;
    }


    .accordion-block-inner {
        flex-direction: column;
    }

    .accordion-block-left {
        width: 100%;
    }

    .accordion-btn {
        font-weight: 500;
        font-size: 10px;
        line-height: 135.5%;
    }

    .accordion-info {
        font-weight: 300;
        font-size: 16px;
        line-height: 171.5%;
    }

    
}