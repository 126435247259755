*,
html,
body {
    font-family: "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.section1 {
    display: flex;
    position: relative;
    min-height: 850px;
}

.blog_mobile_btn {
    display: none;
}
.links_block {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.single_vacancy_file_label {
    // height: 50px;
    // width: 100%;
     border: 2px solid #dadada47;
     box-sizing: border-box;
     border-radius: 5px;
     font-style: normal;
     font-weight: 275;
     font-size: 16px;
     line-height: 23px;
     display: block;
     width: 100%;
     margin-bottom: 20px;
     background: transparent;
     padding: 18px;
     box-sizing: border-box;
     outline: none;
     font-weight: 275;
     font-size: 16px;
     line-height: 23px;
     opacity: 0.7;
     color: #DADADA;

     &.require {
         border: 2px solid red;
     }

     &:focus {
         border: 2px solid #00A5EF;
         box-shadow: rgba(0, 165, 239, 0.17);
     }
     &:hover {
         border: 2px solid #00A5EF;
         box-shadow: rgba(0, 165, 239, 0.17);
     }
}
.single_vacancy_file {
        display: none !important;
}

.shadov-text-blog {
    margin-top: 100px;
}

.blog-page-right {
    display: flex;
}

.services-text {
    margin-top: 113px;
}

.services-page3 {
    margin-top: 100px;
}

.service-page5 {
    margin-top: 50px;
}

.about-shadov-text {
    margin-top: 113px;
}

.contact-shadovtext {
    margin-top: 113px;
}

.portfolio-link-block {
    display: flex;
    justify-content: center;
    margin-top: 130px;
    border: none;
    background: none;
    cursor: pointer;
}

.portfolio-link {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    margin: 0 25px;
    color: #FFFFFF;
    border: none;
    background: none;
    cursor: pointer;
    transition: all ease 0.5s;

    &:hover {
        color: #00A5EF;
    }

    &.activ {
        color: #00A5EF;
    }
}

.portfolio-box {
    margin-top: 76px;
}

.home-carousel {
    width: 100%;
}

.home-carousel {
    .slick-dots {
        display: flex !important;
        justify-content: center !important;

        li {
            list-style: none;
        }

        .slick-active {
            background: #fff !important;
            height: 5px;
            width: 22px;
            margin: 0 10px;
        }

        li {
            margin: 0 10px;
            cursor: pointer;
            background: #4D5096;
            height: 5px;
            width: 22px;
        }

        button {
            border: none;
            margin: 0 10px;
            cursor: pointer;
            background: #4D5096;
            height: 5px;
            width: 22px;
            border: none;
            background: none;
            width: 20px;
            height: 2px;
            overflow: hidden;
        }
    }

}

.blog-single-block {
    width: 60%;
}

.hr_class {
    opacity: 0.1;
    margin: 0;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 1);
}

.error-page {
    height: 100vh;
    background-image: url('../assets/images/jpeg/404.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}

.error-text {
    color: red;
    font-size: 60px;
    padding-top: 100px;
    text-align: center;
}

.error-btn {
    border: none;
    background: none;
    color: #2B2D66;
    background: red;
    padding: 15px 30px;
    border-radius: 15px;
    font-size: 20px;
    cursor: pointer;
    transition: all ease 0.5s;

    &:hover {
        background: #2B2D66;
        color: red;
    }
}


.error-link {
    display: flex;
    justify-content: center;
    text-decoration: none;
    margin-top: 50px;
}
.error{
    color: red !important;
    font-size: 11px;
}
.error-radio{
    border: 2px solid red;
}







////////////////// blog-single-block //////////////
.blog-single-block {
    margin-top: 65px;
}

.blog-post-text {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: #FFFFFF;
    margin-top: 20px;
}

.blog-post-img {
    width: 100%;
    margin-top: 51px;
}

.blog-post-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 42px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    padding-bottom: 30px;
}

.blog-post-div-left {
    display: flex;
    align-items: center;
}

.blog-post-div-right {
    display: flex;
    align-items: center;
}

.eye-number {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin-left: 10px;
    color: #FFFFFF;
}

.share {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 17px;
    text-decoration: none;
    color: #00A5EF;
}

.share-link {
    text-decoration: none;

    margin: 0 15px;
}

.share-icon {
    width: 18px;
    height: 18px;
}

.share-block {
    display: flex;

    button {
        background: none !important;
        cursor: pointer !important;
        border: none !important;
    }
}

.blog-text-block {
    background: #25265B;
    border-radius: 15px;
    padding: 35px;
    margin-top: 60px;
}

.blog-text-btn {
    display: flex;
    justify-content: flex-end;
    // align-items: center;
}

.blog-text-button {
    border: none;
    background: none;
    width: 19px;
    height: 19px;
    cursor: pointer;
}

.text-language {
    margin-right: 30px;
}

.block-info {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 171.5%;
    color: #FFF;
    margin-top: 30px;

    &.activ {
        text-transform: uppercase;
        font-size: 21px;
    }
}


.vacancy_page {
    margin-top: 101px;
}

.vacancy-qol-top {
    position: absolute;
    left: -113px;
    width: 200px;
    top: -200px;
    z-index: 2;
}

.single_vacancy_inner_left {
    width: 45%;
}

.single_vacancy_inner_right {
    width: 45%;
}

.single_vacancy_form_title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 146.5%;
    color: #DADADA;
    margin-bottom: 40px;
}

.single_vacancy_form {
    background: radial-gradient(50% 50% at 0% 50%, #3D3FA3 0%, #2B2D66 100%);
    border: 2px solid rgba(255, 255, 255, 0.0782099);
    box-sizing: border-box;
    box-shadow: 0px 24px 48px rgba(0, 1, 44, 0.157184);
    border-radius: 12px;
    padding: 37px;
    box-sizing: border-box;
}

.single_vacancy_input {
    border: 2px solid #dadada47;
    box-sizing: border-box;
    border-radius: 5px;
    font-style: normal;
    font-weight: 275;
    font-size: 16px;
    line-height: 23px;
    display: block;
    width: 100%;
    margin-bottom: 20px;
    background: transparent;
    padding: 18px;
    box-sizing: border-box;
    outline: none;
    color: white;
    &.require {
        border: 2px solid red;
    }
    &:focus {
        border: 2px solid #00A5EF;
        box-shadow: rgba(0, 165, 239, 0.17);
    }

     &:hover {
         border: 2px solid #00A5EF;
         box-shadow: rgba(0, 165, 239, 0.17);
     }
}

.single_vacancy_button-block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 146.5%;
        color: #DADADA;
        opacity: 0.5;
    }
}

.single_vacancy_btn {
    margin-top: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.2px;
    background: #3D3FA3;
    box-shadow: 0px 4px 31px rgba(0, 165, 239, 0.17);
    border-radius: 8px;
    padding: 14px 56px;
    box-sizing: border-box;
    border: none;
    outline: none;
    cursor: pointer;
    color: #FFFFFF;
}

.single_vacancy_btn_block {
    display: flex;
    justify-content: center;
}

.google_forms_cv {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.2px;
    text-decoration: none;
    padding: 14px 40px;
    background: #6146C6;
    box-shadow: 0px 4px 31px rgba(0, 165, 239, 0.17);
    border-radius: 8px;
    color: #FFFFFF;
}

.single_cv_input_none {
    display: none;
}

.single_cv_input {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.2px;
    background: #00A5EF;
    box-shadow: 0px 4px 31px rgba(0, 165, 239, 0.17);
    border-radius: 8px;
    padding: 14px 29px;
    box-sizing: border-box;
    display: block;
    outline: none;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
}

.single_vacancy_inner_left_title {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: #FFFFFF;
    margin-bottom: 40px;
}

.single_vacancy_inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 90px;
}

.vacancy-qol-bottom {
    position: absolute;
    bottom: -302px;
    right: -150px;
    transform: rotate(180deg);
}

.vacancy_card_block {
    position: relative;
}

.vacancy_card_inner {
    margin-top: 180px;
}

.vacancy_page_inner {
    position: relative;
}

@media only screen and (max-width:920px) {

    .single_vacancy_inner {
        flex-direction: column;
    }

    .single_vacancy_inner_right {
        width: 100%;
        margin-top: 30px;
    }

     .single_vacancy_inner_left {
         width: 100%;
     }


    .section1 {
        display: flex;
        position: relative;
        min-height: 700px;
    }

    .vacancy_page1_block {
        flex-direction: column;
    }


    .vacancy_page1_block_right_img {
        width: 100%;
    }

    .shadov_vacancy {
        margin-top: 30px;
    }

    .blog-page-right {
        flex-direction: column-reverse;
    }

    .blog_mobile_btn {
        border: none;
        background: none;
        width: 125px;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 135.5%;
        background: #00A5EF;
        padding: 6px 25px;
        box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        color: #FFFFFF;
        margin: 25px 0 0 0;
    }

    .blog-single-block {
        width: 100%;
        margin-top: 30px;
    }


    .blog-post-text {
        font-weight: 500;
        font-size: 22px;
        line-height: 23px;
    }

    .blog-text-block {
        padding: 20px;
    }

    .block-info {
        font-size: 16px;
        line-height: 171.5%;
    }

    .portfolio-link-block {
        flex-direction: column;
    }

    .portfolio-link {
        margin: 15px;
        font-size: 12px;
    }

    .portLinkBlock {
        height: 0;
        transition: all ease 0.2s;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        &.active {
            height: 80px;
        }
    }

    .portfolio-link-block {
        flex-direction: column;
        margin-top: 40px;
    }

    .portfolio-box {
        margin-top: 0;
    }

}

@media only screen and (max-width: 880px) {


    .blog-page-right {
        flex-direction: column-reverse;
    }

    .blog_mobile_btn {
        border: none;
        background: none;
        width: 125px;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 135.5%;
        background: #00A5EF;
        padding: 6px 25px;
        box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        color: #FFFFFF;
        margin: 25px 0 0 0;
    }

    .blog-single-block {
        width: 100%;
        margin-top: 30px;
    }


    .blog-post-text {
        font-weight: 500;
        font-size: 28px;
        line-height: 29px;
        margin-top: 20px;
    }

    .blog-text-block {
        padding: 20px;
    }

    .block-info {
        font-size: 16px;
        line-height: 171.5%;
    }

    .blog-page-right {
        flex-direction: column-reverse;
    }

    .blog_mobile_btn {
        border: none;
        background: none;
        width: 125px;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 135.5%;
        background: #00A5EF;
        padding: 6px 25px;
        box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        color: #FFFFFF;
        margin: 25px 0 0 0;
    }

    .blog-single-block {
        width: 100%;
        margin-top: 30px;
    }


    .blog-post-text {
        font-weight: 500;
        font-size: 22px;
        line-height: 23px;
    }

    .blog-text-block {
        padding: 20px;
    }

    .block-info {
        font-size: 16px;
        line-height: 171.5%;
    }

    .portfolio-link-block {
        flex-direction: column;
    }

    .portfolio-link {
        margin: 15px;
        font-size: 12px;
    }

    .portLinkBlock {
        height: 0;
        transition: all ease 0.2s;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        &.active {
            height: 80px;
        }
    }

    .portfolio-link-block {
        flex-direction: column;
        margin-top: 40px;
    }

    .portfolio-box {
        margin-top: 0;
    }
}

@media only screen and (max-width: 480px) {


    .blog-page-right {
        flex-direction: column-reverse;
    }

    .single_vacancy_input {
        padding: 10px;
         font-style: normal;
         font-weight: 275;
         font-size: 14px;
         line-height: 146.5%;
    }


    .single_vacancy_form_title {
        font-weight: 500;
        font-size: 22px;
        line-height: 146.5%;
    }


    .single_cv_input {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.2px;
        text-align: center;
        padding: 10px 15px;
    }

    .single_vacancy_btn {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.2px;
        text-align: center;
        // padding: 10px 15px;
        margin-top: 32px;

    }

    .single_vacancy_button-block {
        margin-top: 60px;
    }

    .google_forms_cv {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.2px;
        text-align: center;
        padding: 10px 15px;
    }
    .blog_mobile_btn {
        border: none;
        background: none;
        width: 125px;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 135.5%;
        background: #00A5EF;
        padding: 6px 25px;
        box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        color: #FFFFFF;
        margin: 25px 0 0 0;
    }

    .blog-single-block {
        width: 100%;
        margin-top: 30px;
    }



    .blog-post-text {
        font-weight: 500;
        font-size: 22px;
        line-height: 23px;
    }

    .blog-text-block {
        padding: 20px;
    }

    .block-info {
        font-size: 16px;
        line-height: 171.5%;
    }

    .portfolio-link-block {
        flex-direction: column;
    }

    .portfolio-link {
        margin: 0px;
        font-size: 12px;
    }

    .portLinkBlock {
        height: 0;
        transition: all ease 0.2s;
        overflow: hidden;
        display: flex;
        align-items: center;

        &.active {
            height: 80px;
        }
    }

    .portfolio-link-block {
        flex-direction: column;
        margin-top: 40px;
    }

    .portfolio-box {
        margin-top: 0;
    }



    .vacancy-qol-top {
        display: none;
    }

    .vacancy-qol-bottom {
        display: none;
    }

    .vacancy_card_inner {
        margin-top: 70px;
    }

    .single_vacancy_form {
        padding: 25px;
    }

}


.ticket_block_top {
    margin-top: 12px;
    background-color: #fff;
    border: 1px solid #dadce0;
    border-radius: 8px;
    margin-bottom: 12px;
    padding: 24px;
    padding-top: 22px;
    position: relative;
    border-top: 20px solid rgb(0, 165, 239);
    // border-top: 20px solid blue;
    max-width: 550px;
    // background-color: rgb(0, 165, 239);
}

.ticket_block_title {
    font-weight: 400;
    font-size: 24pt;
    line-height: 1.25;
    letter-spacing: 0;
}

.ticket_block_description {
        font-weight: 400;
    font-size: 11pt;
    line-height: 1.5;
    letter-spacing: 0;
}

.ticket_block_botton {
     margin-top: 12px;
    background-color: linear-gradient(to bottom left, #36377c, #181942, #181942, #181942, #36377c);
    border: 1px solid #dadce0;
    border-radius: 8px;
    margin-bottom: 12px;
    padding: 24px;
    padding-top: 22px;
    position: relative;
    // border-top: 20px solid blue;
    // background-color: rgb(0, 165, 239);
    p{
        color: #dadce0;
    }
    h2{
        color: #dadce0;
    }
    input{
        color: #dadce0;
        
    }
    textarea{
        color: #dadce0;
    }
    label{
        color: #dadce0;
    }
}

.ticket_block_bottom_title {
    font-weight: 400;
    font-size: 24pt;
    line-height: 1.25;
    letter-spacing: 0;
}

.ticket_block_bottom_input {
    border:none;
    background:none;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .2px;
    line-height: 20px;
    color: #202124;
    outline: none;
    border-bottom:1px solid rgb(186, 184, 184);
    margin-bottom: 10px;
    width: 100%;
}
.ticket_description{
    opacity: 0.5;
}
.ticket_block_botton_inner label {
    font-weight: 400;
    font-size: 11pt;
    line-height: 1.5;
    letter-spacing: 0;
    margin-left: 10px;
}

.checkbox_in {
     border:none;
    background:none;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .2px;
    line-height: 20px;
    color: #202124;
    outline: none;
    border-bottom:1px solid rgb(186, 184, 184);
    margin-bottom: 10px;
    margin-left: 10px;
}

.tickent_btn {
    background:#1a73e8;
    border: none;
    padding: 7px 18px;
    cursor: pointer;
    border-radius:7px;
    color: white;
    -webkit-border-radius:7px;
    -moz-border-radius:7px;
    -ms-border-radius:7px;
    -o-border-radius:7px;
    margin-top: 20px;
}

.ticket {
    margin: 50px auto 0 auto;
}