.left-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgba(255, 255, 255, 0.12);
    position: absolute;
    min-height: 850px;
    padding: 0 15px;
    z-index: 10;


}

.anime-box2 {
  display: none !important;
}

.vacancy_left-block {
  top: 50px;

    .anime-box {
    display: none !important;
    }
}
.social-network-link {
    margin: 10px 0;
    opacity: 0.7;
    transition: all ease 0.5s;
    &:hover {
      opacity: 1;
    }
}


.anime-box {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 49px;
    background-color: #00A5EF;
    animation: 1s linear 1s infinite running boxAnime;
}

@keyframes boxAnime {
    from {
      box-shadow: 0px 0px 0 0 rgb(104 185 70 / 52%);
    }
    to {
      box-shadow: 0px 0px 0 20px rgb(104 185 70 / 0%);
    }
  }

   @media only screen and (max-width: 960px) {

    .left-block {
      display: none;
    }

   }
  @media only screen and (max-width: 480px) {

    .left-block {
      display: none;
    }

    .anime-box {
      display: none;
    }
  }

  