.home-page9 {
    margin-top: 100px;
    position: relative;


}

.like {
    position: absolute;
    bottom: -123px;
    left: -145px;
}


