.text-link {
    display: flex;
    align-items: center;
    padding: 15px 0 15px 50px;
}

.text-link-home {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    position: relative;

    &:first-child::before {
        content: "";
        position: absolute;
        top: -1px;
        left: -25px;
        bottom: 0;
        width: 25px;
        height: auto;
        background-image: url('../../assets/images/img/blog/Iconly/Light/Home.svg');
        background-repeat: no-repeat;
    }

    &:nth-child(2) {
        margin-left: 5px;
    }

    &:nth-child(3) {
        margin-left: 5px;
    }
}