.about-page2 {
    margin-top: 50px;
}

@media only screen and (max-width: 480px) {


    .about-page2 {
        margin-top: 0;
    }
}