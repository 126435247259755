.youtube-iframe_mobile {
      width: 547px;
      height: 266px;
      display: flex;
      margin: 100px auto 0 auto;
      z-index: 11;
      position: relative;
      left: 0px;
      border-radius: 40px;
      top: -42px;
}

.home-page6-text {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  max-width: 400px;
  text-align: center;
  margin-top: 50px;
  line-height: 38px;
  color: #ffffff;
  display: flex;
  margin: 50px auto 0 auto;
}

.youtube-images {
  position: absolute;
  top: -51px;
  left: -64px;
  right: 0;
}

.home-page6 {
  position: relative;
  background-image: url("../../../assets/images/img/home/youtube-back.png");
  background-repeat: no-repeat;
  background-size: contain;
  padding-bottom: 280px;
  margin-top: 115px;
}

.youtube-video {
  width: 100%;

  ul {
    display: none !important;
  }
}

.slick-controllers3 {
  display: flex;
  justify-content: center;
  margin-left: 30px;

  .btn-caro {
    margin: 0 10px;
    user-select: none;
  }
}

@media only screen and (max-width: 1024px) {
  .youtube-iframe_mobile {
    width: 480px;
    height: 234px;
    display: flex;
    margin: 100px auto 0 auto;
    z-index: 11;
    position: relative;
    left: 0px;
    border-radius: 36px;
    top: -79px;
  }

  .home-page6 {
    background-image: none;
    padding-bottom: 100px;
  }

  .slick-controllers3 {
    margin-left: 80px;
  }
}

@media only screen and (max-width: 960px) {


}


@media only screen and (max-width: 480px) {

  .home-page6 {
    background-image: none;
    padding-bottom: 0;
  }

  .home-page6-text {
    font-size: 28px;
    text-align: center;
  }


  .youtube-iframe_mobile {
    width: 100%;
    height: 240px;
  }

  .slick-controllers3 {
    margin: auto;
    position: relative;
    top: -25px;
  }
}
