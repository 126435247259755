.about-page1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 130px;
}

.iframe_about {
    width: 550px;
    height: 326px;
    border-radius: 15px;
}

.about-page1-text {
    font-style: normal;
    font-weight: 350;
    font-size: 48px;
    line-height: 58px;
    max-width: 462px;
    color: #FFFFFF;
}

.about-page1-info {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 171.5%;
    opacity: 0.7;
    margin-top: 20px;
    max-width: 462px;
    color: #FFFFFF;
}


@media only screen and (max-width: 1024px) {



    .iframe_about {
        max-width: 450px;
        height: 326px;
    }
}


@media only screen and (max-width: 960px) {


    .about-page1 {
        flex-direction: column-reverse;
        margin-top: 40px;
        align-items: flex-start;

    }


    .about-page1-text {
        font-weight: 500;
        font-size: 38px;
        line-height: 46px;
    }

    .about-page1-info {
        font-weight: 300;
        font-size: 16px;
        line-height: 171.5%;
        max-width: 100%;
    }

    

    .iframe_about {
        max-width: 600px;
        height: 300px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }

    .about-page1-right {
        width: 100%;
    }


}


@media only screen and (max-width: 480px) {


    .about-page1 {
        flex-direction: column-reverse;
        margin-top: 40px;
    }


    .about-page1-text {
        font-weight: 500;
        font-size: 38px;
        line-height: 46px;
    }

    .about-page1-info {
        font-weight: 300;
        font-size: 16px;
        line-height: 171.5%;
    }

    .iframe_about {
        max-width: 100%;
        height: 200px;
        margin-top: 30px;
    }


}