.home-page1 {
    display: flex;
    justify-content: space-between;
    margin-top: 165px;
    align-items: center;

    .slick-dots {
        list-style: none !important;
        ul {
            li {
                list-style: none !important;
            }
        }
    }
}

.home-page-text {
    font-style: normal;
    font-weight: 350;
    font-size: 48px;
    line-height: 58px;
    color: #FFFFFF;
}

.home-page1-text-info {
    font-style: normal;
    font-weight: 300;
    opacity: 0.7;
    margin-top: 48px;
    font-size: 16px;
    line-height: 171.5%;
    color: #FFFFFF;
}

.home-page1-link-block {
    display: flex;
    align-items: center;
    margin-top: 60px;
    // overflow: hidden;
}

.home-page1-link {
    padding: 15px 29px;
    background: transparent;
    display: inline-block;
    text-decoration: none;
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    transition: all ease 0.5s;
    border: 1px solid #fff;
    &:hover {
        background: #00A5EF;
        color: #fff;
        border: 1px solid transparent;
    }
}



.hidden {
    overflow: hidden;
}

.home-page1-pages {
    width: 100%;
    transform: scale(1.1);
}

.home-page1-right {
    width: 59%;
}

.home-page1-left {
    width: 41%;
}

.home-page1-link-text {
    text-decoration: none;
    color: #fff;
}

.link-order-your {
   

     padding: 15px 29px;
     background: #00A5EF;
     display: inline-block;
     text-decoration: none;
     box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
     border-radius: 8px;
     font-weight: 500;
     font-size: 16px;
     line-height: 19px;
     letter-spacing: 0.2px;
     color: #FFFFFF;
     transition: all ease 0.5s;
     border: 1px solid transparent;

      text-decoration: none;
      color: white;
      box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
        margin-right: 50px;
}

@media only screen and (max-width: 780px) {


    .home-page1 {
        flex-direction: column-reverse;
        margin-top: 44px;
    }
    .home-page1-left {
        width: 100%;
    }
    .home-page1-right {
        width: 100%;
    }

    .home-page-text {
        font-size: 24px;
        line-height: 29px;
        margin-top: 15px;
    }

    .home-page1-text-info {
        font-weight: 300;
        font-size: 16px;
        line-height: 171.5%;
        margin-top: 15px;
    }

    .home-page1-link-block {
        justify-content: space-around;
        margin-bottom: 20px;
    }

    .home-page1-link {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
    }

    .link-order-your {
        margin-right: 0;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
    }
}